<section class="section about" id='about'>
    <div class="container">
        <div class="section-box">
            <div class="about-title" data-aos="fade-up">
                <h3 class="section-title">
                    <span class='n-section-title'>01.</span> {{"AboutMe.Title" | translate}}
                </h3>
            </div>
            <div class="row">
                <div class="col-12 col-md-6 mb-4 mb-md-0">
                    <div class="about-description">
                        <p *ngFor='let text of "AboutMe.Paragraphs" | translate' [innerHTML]="text" data-aos="fade-up" data-aos-duration="1000">
                        </p>
                        <ul class="skills-list" data-aos="fade-up">
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">Angular , Nx </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">RxJS , RxJS Store</span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">HTML &amp; (S)CSS </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">.NET and .NET Core </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">Python and Django </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">WordPress </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">Cypress / Karma (TDD) </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">CI / CD </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">AWS , Github </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">MongoDb , Postgres </span></li>

                        </ul>
                    </div>
                </div>
                <div class="col-12 col-md-6 mt-4 mt-md-0 text-center" data-aos="fade-up" data-aos-duration="1000">
                    <div class="about-img-container">
                        <img (click)='analyticsService.sendAnalyticEvent("click_image", "about", "image")' class="rounded" width="400" height="600" src="assets/images/masud-me-2.jpeg" alt="José Andrés">
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>
